import Founders from "../../images/portrait.png";

const STRINGS = [
  {
    title: "Finally, an easier way to resell",
    text: `There's never been a better time to own a reselling business. The second-hand market is growing quickly, consumer shopping habits are shifting, and more and more companies are launching platforms to buy and sell. With exciting growth, comes new problems. But luckily, Fearn provides one centralized hub for all reselling business solutions.`,
    image: null,
  },
  {
    title: "Our commitment to growth",
    text: `We know from experience that as your reselling business grows, you'll need software that grows alongside you. That is why we are proud to be 100% self-funded, although we prefer the term "customer-funded". With no external pressures from outside money, we can remain focused on our singular goal: to make the best product possible for our customers.`,
    image: null,
  },
  {
    title: "Meet Morgan & Matt",
    text: `Our founders, Morgan and Matt, know reselling. Morgan started reselling at the age of 16 and took it on as a full-time career in 2018. Matt joined in 2019. They created Fearn because they were tired of overpaying for a variety of software that failed to fully meet their needs. Scattered across many different services on many platforms, they were convinced that there had to be a better way. And so, they set out to create the first one-stop shop where resellers can manage every aspect of their business, regain their time back, and increase profitability.`,
    image: Founders,
  },
];

export default STRINGS;
