import React, { useEffect } from "react";
import { useIntercom } from "react-use-intercom";

import STRINGS from "../../constants/About-us";
import PageLayout from "../../components/PageLayout";
import Header from "../../components/Header";
import TermsTitleSection from "../../sections/AboutUs/TermsTitleSection/index";
import ContentText from "../../components/ContentText";
import Footer from "../../components/Footer";

const PageContent = () => {
  const { boot } = useIntercom();

  useEffect(() => {
    boot();
  }, []);

  return (
    <>
      <TermsTitleSection title="About us" />
      <ContentText content={STRINGS} />
      <Footer />
    </>
  );
};

const AboutUs = () => {
  return (
    <>
      <Header />
      <PageLayout title="About us">
        <PageContent />
      </PageLayout>
    </>
  );
};

export default AboutUs;
